@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.scgl {
  width: 100%;
  height: 100%;
  .scgl-row1 {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
    }
  }
  .scgl-row2 {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 57px);
    .scgl-row2-card {
      padding: 10px 0;
      height: 100%;
      overflow-y: scroll;
      .scgl-card-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        .scgl-item {
          margin-bottom: 40px;
          margin-right: 20px;
          width: 198px;
          .scgl-item-image {
            height: 148px;
            background: rgba(236, 234, 250, 1);
            border-radius: 4px;
            position: relative;
            .scgl-left {
              position: absolute;
              top: -5px;
              left: 0;
            }
            .right {
              position: absolute;
              top: 0;
              right: 0;
              height: 20px;
              width: 54px;
              font-size: 14px;
              color: #fff;
              text-align: center;
              line-height: 20px;
              border-radius: 4px;
            }
            .item-image-mask {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgb(0, 0, 0, 0.8);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 4px;
              color: #fff;
            }
          }
          .item-message {
            font-size: 14px;
            .row1 {
              margin-top: 10px;
            }
            .row2 {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
